import { selector, atom } from '@src/libs/recoil/recoil';
import { companiesProfileApi, companiesSearchApi } from '@src/utils/api/api';
import { ApiResFormat } from '@src/utils/api/api-types';
import { cinAtom } from '@src/report/atoms/reports-atoms';
import { formatDate } from '@turbocomplynpm/date-utils';
import {
  COMPANIES_SEARCH_SELECTOR,
  COMPANIES_SEARCH_ATOM,
  COMPANIES_PROFILE_SELECTOR,
  COMPANIES_PROFILE_REFRESH_ATOM,
  COMPANIES_SEARCH_REFRESH_ATOM,
  COMPANIES_SEARCH_REFETCH_ATOM,
} from './home-actions';
import type {
  CompaniesProfileResponse,
  CompaniesProfileSeletorType,
  CompaniesSearchResponse,
} from './home-types';

export const companiesSearchAtom = atom({
  key: COMPANIES_SEARCH_ATOM,
  default: '',
});

export const companiesSearchRefetchAtom = atom({
  key: COMPANIES_SEARCH_REFETCH_ATOM,
  default: false,
});

export const companiesSearchRefreshAtom = atom({
  key: COMPANIES_SEARCH_REFRESH_ATOM,
  default: 0,
});

export const companiesSearchSelector = selector({
  key: COMPANIES_SEARCH_SELECTOR,
  get: async ({ get }) => {
    const search = get(companiesSearchAtom);
    const refetch = get(companiesSearchRefetchAtom);
    get(companiesSearchRefreshAtom);
    if (!search) {
      return {
        companiesOptions: [],
      };
    }

    const companiesResponse: ApiResFormat<CompaniesSearchResponse[]> =
      await companiesSearchApi({
        search,
        refetch,
      });

    const companiesOptions = companiesResponse.data.data.map(
      (companiesData) => ({
        ...companiesData,
        label: companiesData.name,
        value: companiesData.cin,
      }),
    );

    return {
      ...companiesResponse.data,
      companiesOptions,
    };
  },
});

export const companiesProfileRefreshAtom = atom({
  key: COMPANIES_PROFILE_REFRESH_ATOM,
  default: 0,
});

export const companiesProfileSelector = selector<CompaniesProfileSeletorType>({
  key: COMPANIES_PROFILE_SELECTOR,
  get: async ({ get }) => {
    const cin = get(cinAtom);
    const refreshAtomCount = get(companiesProfileRefreshAtom);
    const dummyProfile: CompaniesProfileResponse = {
      id: '',
      name: '',
      cin: '',
      inserted_at: '',
      updated_at: '',
      state: '',
      city: '',
      date_of_registration: '',
    };

    const defaultResponse = {
      profile: {
        ...dummyProfile,
        registrationDate: '',
        ageOfCompany: '',
        activity: '',
      },
      gstins: [],
      refetchRequired: false,
    };

    if (!cin) {
      return defaultResponse;
    }
    const companiesProfileResponse: ApiResFormat<CompaniesProfileResponse> =
      await companiesProfileApi({
        cin,
      });
    const profile = companiesProfileResponse.data.data;
    if (!profile) {
      return {
        ...defaultResponse,
        refetchRequired: refreshAtomCount < 2,
      };
    }
    const gstins = profile.comply_companies_gst?.map((gst) => gst.gstin) ?? [];
    return {
      profile: {
        ...profile,
        registrationDate: formatDate({ date: profile.date_of_registration }),
        ageOfCompany: '',
        activity: '',
      },
      gstins,
      refetchRequired: false,
    };
  },
});
