import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { useLocation } from '@src/libs/router/react-router';
import ErrorBoundary from '../ErrorBoundary/error-boundary';
import type {
  ErrorBoundaryType,
  ResetKeysType,
} from '../ErrorBoundary/error-boundary.types';
import SpinnerLoader from './spinner-loader';
import { SkeletonLoader } from '../SkeletonLoader/skeleton-loader';

type DefaultProps = {
  loaderType?: 'spinner' | 'skeleton';
};

type Props = {
  children: ReactNode;
  loaderClass?: string;
} & ErrorBoundaryType &
  DefaultProps;

function SuspenseLoader({
  children,
  reset,
  resetKeys,
  loaderType = 'skeleton',
  loaderClass,
}: Props) {
  const [resetKeysState, setResetKeysState] = useState<
    ResetKeysType[] | undefined
  >(resetKeys);
  const { pathname } = useLocation();
  useEffect(() => {
    if (typeof resetKeys === 'undefined') {
      setResetKeysState([pathname]);
    } else {
      setResetKeysState([...resetKeys, pathname]);
    }
  }, [pathname, resetKeys]);

  const Loader =
    loaderType === 'spinner' ? (
      <SpinnerLoader />
    ) : (
      <SkeletonLoader className={loaderClass} />
    );
  return (
    <ErrorBoundary reset={reset} resetKeys={resetKeysState}>
      <Suspense fallback={Loader}>{children}</Suspense>
    </ErrorBoundary>
  );
}

export default SuspenseLoader;
