import React from 'react';
import { Select as SelectComponent, SelectProps } from '@turbocomplynpm/select';
import { useToken } from '@src/hooks/use-token';
import { FieldValues } from '@src/libs/forms/react-hook-forms';

export function Select<T extends FieldValues>(props: SelectProps<T>) {
  const token = useToken();
  return <SelectComponent {...props} theme={token} />;
}

export const { Option } = SelectComponent;
