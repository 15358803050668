import React, { lazy, useEffect } from 'react';
import classNames from 'classnames/bind';
import { defaultTheme } from '@turbocomplynpm/theme-provider';
import { ConfigProvider } from 'antd';
import { RecoilRoot } from '@src/libs/recoil/recoil';
import * as styles from './layout.module.css';
import { Outlet } from './libs/router/react-router';
import { generateColorVars } from './utils/colors/color-helpers';
import SuspenseLoader from './uikit/SuspenseLoader/suspense-loader';
import { font_family, primary_blue } from './utils/colors/colors';
import { Footer } from './footer';

const cx = classNames.bind(styles);
const Header = lazy(() => import('@src/header/header'));

function Layout() {
  useEffect(() => {
    generateColorVars();
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          ...defaultTheme.token,
          colorPrimary: primary_blue,
          colorInfo: primary_blue,
          colorLink: primary_blue,
          fontFamily: font_family,
          fontSize: 16,
        },
        components: {
          Table: {
            cellFontSize: 20,
            cellPaddingBlock: 12,
            cellPaddingInline: 12,
            headerBg: 'transparent',
            colorBgContainer: 'transparent',
            borderColor: 'none',
            fontSizeHeading2: 10,
          },
        },
      }}
    >
      <RecoilRoot>
        <SuspenseLoader loaderType="skeleton">
          <Header />
        </SuspenseLoader>
        <div className={cx('layout')}>
          <Outlet />
        </div>
        <Footer />
      </RecoilRoot>
    </ConfigProvider>
  );
}

export default Layout;
