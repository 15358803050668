import { CompanyInfo } from './reports-types';

export const GET_GST_FILINGS_SELECTOR = 'reports/GET_GST_FILINGS_SELECTOR';
export const GST_ATOM = 'reports/GST_ATOM';
export const CIN_ATOM = 'reports/CIN_ATOM';
export const COMPANY_INFO_SELECTOR = 'reports/COMPANY_INFO_SELECTOR';

export const Months: Record<string, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const FILING_TYPES = {
  GSTR1: 'GSTR1',
  GSTR1A: 'GSTR1A',
  GSTR8: 'GSTR8',
  GSTR3B: 'GSTR3B',
  GSTR9: 'GSTR9',
  GSTR9C: 'GSTR9C',
};

export const MOCK_COMPANY_INFO: CompanyInfo[] = [
  {
    company_details: {
      cin: 'U72900DL2021PTC377427',
      gstin: '07AABCY3516F1ZI',
      company_name: 'YELLOW LAMBDA TECHNOLOGIES PRIVATE LIMITED',
      company_status: 'Active',
      roc: 'ROC Delhi',
      registration_number: '377427',
      company_category: 'Company limited by shares',
      company_sub_category: 'Non-government company',
      class_of_company: 'Private',
      // eslint-disable-next-line sonarjs/no-duplicate-string -- This is a mock data
      date_of_incorporation: '23 February 2021',
      age_of_company: '3 years, 6 month, 0 days',
      number_of_members: '0',
      activity:
        'Other computer related activities [for example maintenance of websites of other firms/ creation of multimedia presentations for other firms etc.]',
    },
    authorized_capital: '₹1,050,000',
    paid_up_capital: '₹143,000',
    number_of_employees: null,
    listing_status: 'Unlisted',
    last_annual_general_meeting: 'N/A',
    latest_balance_sheet: '31 March 2023',
    contact_details: {
      email: 'DIVYAANSHKUMAR19@GMAIL.COM',
      address:
        'RZ-1/15, Street no. 4, Tughlakabad Extension Near Alaknanda Market   , Delhi, Delhi, India - 110019',
    },
    director_details: [
      {
        din: '09078332',
        director_name: 'DIVYAANSH KUMAR',
        designation: 'Managing Director',
        appointment_date: '23 February 2021',
        other_associated_companies: [],
      },
      {
        din: '09078383',
        director_name: 'DOLLY SETHI',
        designation: 'Director',
        appointment_date: '23 February 2021',
        other_associated_companies: [],
      },
      {
        din: '10049392',
        director_name: 'AMANDEEP ANGURALLA',
        designation: 'Director',
        appointment_date: '04 April 2023',
        other_associated_companies: [],
      },
      {
        din: '10547198',
        director_name: 'GURUDEEP SINGH',
        designation: 'Director',
        appointment_date: '26 March 2024',
        other_associated_companies: [],
      },
    ],
    prosecution_details: [],
    charges_borrowing_details: [
      {
        charge_id: '100758962',
        creation_date: '2023-07-24',
        modification_date: '-0001-11-30',
        closure_date: '-0001-11-30',
        assets_under_charge: '',
        amount: '225,000',
        charge_holder: 'SBI CARDS AND PAYMENT SERVICES LIMITED',
      },
    ],
    establishment_details: [
      {
        establishment_name: 'No establishments found',
      },
    ],
  },
  {
    company_details: {
      cin: 'U74999HR2021PTC099434',
      gstin: '29AABCZ8592J1ZG',
      company_name: 'ZONATH CONSULTING PRIVATE LIMITED',
      company_status: 'Active',
      roc: 'ROC Delhi',
      registration_number: '99434',
      company_category: 'Company limited by shares',
      company_sub_category: 'Non-government company',
      class_of_company: 'Private',
      date_of_incorporation: '24 November 2021',
      age_of_company: '2 years, 8 month, 29 days',
      number_of_members: '0',
      activity: 'Business activities n.e.c.',
    },
    authorized_capital: '₹100,000',
    paid_up_capital: '₹100,000',
    number_of_employees: null,
    listing_status: 'Unlisted',
    last_annual_general_meeting: 'N/A',
    latest_balance_sheet: '31 March 2023',
    contact_details: {
      email: 'akconsultants2019@gmail.com',
      address:
        '18,Kachnar Marg,DLF,GURUGRAM   , GURUGRAM, Haryana, India - 122002',
    },
    director_details: [
      {
        din: '09496541',
        director_name: 'SUNIL KUMAR SINHA',
        designation: 'Director',
        appointment_date: '31 August 2023',
        other_associated_companies: [
          {
            company: 'STUDIO ATARI PRIVATE LIMITED',
            designation: 'Director',
            appointment_date: '08 February 2022',
          },
        ],
      },
      {
        din: '10104815',
        director_name: 'ANURAG SINGH',
        designation: 'Director',
        appointment_date: '17 April 2023',
        other_associated_companies: [],
      },
    ],
    prosecution_details: [],
    charges_borrowing_details: [],
    establishment_details: [],
  },
];
