import { GstFilingsSelector } from './reports-types';

export const defaultGstSummary: GstFilingsSelector = {
  gstFilings: [],
  filteredGstFilings: [],
  onTimeCount: 0,
  lateCount: 0,
  ontimeFilingPercentage: 0,
  fileStartDate: '',
  lastFiledDate: '',
};
