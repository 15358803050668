import { dayjs } from '@src/libs/date/date';
import { getFilingDate } from '@turbocomplynpm/date-utils';

export interface GetDelayedDaysParams {
  dateOfFiling: string;
  dueDate: string;
  filedOnTime: boolean;
}

export const getDelayedDays = ({
  dateOfFiling,
  dueDate,
  filedOnTime,
}: GetDelayedDaysParams) => {
  const date = getFilingDate(dateOfFiling);
  const dueDateObj = dayjs(dueDate).toDate();

  if (filedOnTime) {
    return 0;
  }

  const diff = date.getTime() - dueDateObj.getTime();
  const days = diff / (1000 * 60 * 60 * 24);

  return Math.round(days);
};

export interface GetNullableDelayedDaysParams {
  dateOfFiling: string;
  dueDate?: string;
  filedOnTime?: boolean;
}

export const getNullableDelayedDays = ({
  dateOfFiling,
  dueDate,
  filedOnTime,
}: GetNullableDelayedDaysParams) => {
  if (typeof filedOnTime === 'undefined' || typeof dueDate === 'undefined') {
    return undefined;
  }

  return getDelayedDays({ dateOfFiling, dueDate, filedOnTime });
};
