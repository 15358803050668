import dayjs from 'dayjs';

export { addDueDate } from '@turbocomplynpm/date-utils';

export { dayjs };

type GetFormattedDateParams = {
  date: string;
  existingFormat?: string;
  customFormat?: string;
};
export function getFormattedDate({
  date,
  existingFormat,
  customFormat,
}: Readonly<GetFormattedDateParams>) {
  return dayjs(date, existingFormat).format(customFormat || 'DD MMM YYYY');
}
