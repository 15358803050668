import React from 'react';
import classNames from 'classnames/bind';
import { Skeleton } from 'antd';
import * as styles from './skeleton-loader.module.css';

const cx = classNames.bind(styles);
type Props = {
  className?: string;
};

export function SkeletonLoader({ className }: Props) {
  return <Skeleton active className={cx(className)} />;
}
