import React, { lazy } from 'react';

import { Flex } from '@turbocomplynpm/flex';
import { Paragraph, Title } from '@src/uikit/typography/typography';
import { useRouteError } from './libs/router/react-router';
import SuspenseLoader from './uikit/SuspenseLoader/suspense-loader';

const Error404 = lazy(() => import('@turbocomplynpm/animation-404'));

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  // 404 error
  if (
    typeof error === 'object' &&
    error &&
    'status' in error &&
    error.status === 404
  ) {
    return (
      <SuspenseLoader>
        <Flex middle center>
          <Error404 />
        </Flex>
      </SuspenseLoader>
    );
  }

  if (
    typeof error === 'object' &&
    error &&
    'statusText' in error &&
    'message' in error
  ) {
    const statusText =
      typeof error.statusText === 'string' ? error.statusText : '';
    const message = typeof error.message === 'string' ? error.message : '';
    return (
      <Flex middle center>
        <Title>Oops!</Title>
        <Paragraph>Sorry, an unexpected error has occurred.</Paragraph>
        <Paragraph>
          <i>{statusText || message}</i>
        </Paragraph>
      </Flex>
    );
  }
  return (
    <Flex middle center>
      <Title>Oops!</Title>
      <Paragraph>Sorry, an unexpected error has occurred.</Paragraph>
    </Flex>
  );
}
