import React from 'react';
import { Spin } from 'antd';
import { Flex } from '@turbocomplynpm/flex';

function SpinnerLoader() {
  return (
    <Flex center middle>
      <Spin data-testid="spinner-loader" />
    </Flex>
  );
}

export default SpinnerLoader;
