import React from 'react';
import classNames from 'classnames/bind';
import { Flex } from '@turbocomplynpm/flex';
import { Button } from '@turbocomplynpm/button';
import { SvgRefresh } from '@turbocomplynpm/icons';
import * as styles from './error-text.module.css';
import { Paragraph, Title } from '../typography/typography';

const cx = classNames.bind(styles);

type Props = {
  errorMessage: string;
  handleClear: React.MouseEventHandler<HTMLElement>;
};
function ErrorText({ errorMessage, handleClear }: Readonly<Props>) {
  return (
    <div className={cx('error-text')}>
      <Flex row center>
        <Title level={4} align="center">
          Sorry, something went wrong.
        </Title>
        <Button onClick={handleClear} shape="round" className={cx('button')}>
          <SvgRefresh />
        </Button>
      </Flex>
      <Paragraph align="center">
        We&apos;re working on it and we&apos;ll get it fixed as soon as we can
      </Paragraph>
      <Flex middle>
        <Paragraph align="center" type="danger" className={cx('error-message')}>
          {errorMessage}
        </Paragraph>
      </Flex>
    </div>
  );
}

export default ErrorText;
