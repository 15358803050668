import { useCallback, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { useRecoilState, useSetRecoilState } from '@src/libs/recoil/recoil';
import { useLocation, useParams } from '@src/libs/router/react-router';
import { ReportsParams } from './atoms/reports-types';
import { cinAtom, gstAtom } from './atoms/reports-atoms';

interface SetCinAtom {
  cin: string;
  gst: string[];
}

interface SetGstAtomParams {
  gst: string;
  gstList: string[];
}

const getGstinMaps = (gstins: string[]) =>
  gstins.reduce((acc: Record<string, string>, curr: string) => {
    acc[curr] = curr;
    return acc;
  }, {});

export const useCinHook = () => {
  const { cin: cinParams } = useParams<ReportsParams>();
  const { search } = useLocation();
  const setCinAtom = useSetRecoilState(cinAtom);
  const [{ gst: gstVal }, setGstAtom] = useRecoilState(gstAtom);
  useEffect(() => {
    if (cinParams) {
      setCinAtom(cinParams);
    }
  }, [setCinAtom, cinParams]);

  const handleSetGstAtom = useCallback(
    ({ gst, gstList }: SetGstAtomParams) => {
      const gstMap = getGstinMaps(gstList);
      setGstAtom((prev) => {
        if (prev.gst === gst) {
          return prev;
        }
        return {
          gst,
          gstMap,
        };
      });
    },
    [setGstAtom],
  );
  const urlParams = queryString.parse(search);
  const gstinValues = urlParams.gstins;

  const gstins = useMemo(() => {
    let gstinList: string[] = [];
    if (typeof gstinValues === 'string') {
      gstinList = [gstinValues];
    }
    if (Array.isArray(gstinValues)) {
      const gstinsStrings = gstinValues
        .filter((gst) => typeof gst === 'string')
        .map((gst: string) => gst);
      gstinList = gstinsStrings;
    }
    return gstinList;
  }, [gstinValues]);

  useEffect(() => {
    if (!gstVal) {
      const [firstGst] = gstins;
      handleSetGstAtom({
        gst: firstGst,
        gstList: gstins,
      });
    }
  }, [gstins, handleSetGstAtom, gstVal]);

  const handleSetCinAtom = ({ cin, gst }: SetCinAtom) => {
    const [firstGst] = gst;
    setCinAtom(cin);
    handleSetGstAtom({
      gst: firstGst,
      gstList: gst,
    });
  };

  return {
    cin: cinParams,
    companyName:
      typeof urlParams.companyName === 'string' ? urlParams.companyName : '',
    finYear: typeof urlParams.finYear === 'string' ? urlParams.finYear : '',
    gstins,
    handleSetCinAtom,
  };
};
