import React from 'react';
import classNames from 'classnames/bind';
import {
  Paragraph as ParagraphComponent,
  ParagraphProps as ParagraphProperty,
  Title as TitleComponent,
  TitleProps as TitleProperty,
} from '@turbocomplynpm/typography';
import * as styles from './typography.module.css';

const cx = classNames.bind(styles);

export interface ParagraphProps extends ParagraphProperty {
  localSize?: '14' | '16' | '18';
  weight?: 'regular' | 'bold';
}

export function Paragraph({
  children,
  className,
  weight = 'regular',
  localSize,
  ...props
}: Readonly<ParagraphProps>) {
  return (
    <ParagraphComponent
      className={cx(className, {
        [`paragraph-${weight}`]: weight,
        [`paragraph-${localSize}`]: localSize,
      })}
      {...props}
    >
      {children}
    </ParagraphComponent>
  );
}

export function Title({
  children,
  className,
  ...props
}: Readonly<TitleProperty>) {
  return (
    <TitleComponent className={cx(className, 'title')} {...props}>
      {children}
    </TitleComponent>
  );
}
