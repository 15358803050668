import { selector, atom } from '@src/libs/recoil/recoil';
import { getGstFilingsApi, getCompanyInfoApi } from '@src/utils/api/api';
import { ApiResFormat } from '@src/utils/api/api-types';
import { addDueDate, dayjs } from '@src/libs/date/date';
import { getDelayedDays } from '@src/summary/utils/summary-helpers';
import {
  COMPANY_INFO_SELECTOR,
  FILING_TYPES,
  GET_GST_FILINGS_SELECTOR,
  CIN_ATOM,
  GST_ATOM,
} from './reports-actions';
import type {
  CompanyInfo,
  FormattedGstFilings,
  GstAtomType,
  GstFilings,
  GstFilingsSelector,
  IsFiledOnTimeParams,
} from './reports-types';
import { getFilingDate } from '../report-helper';
import { defaultGstSummary } from './reports-constants';

export const cinAtom = atom({
  key: CIN_ATOM,
  default: '',
});

export const gstAtom = atom<GstAtomType>({
  key: GST_ATOM,
  default: {
    gst: '',
    gstMap: {},
  },
});

// financial year is from 1st april to 31st march
const getTaxableYear = (filing: GstFilings) => {
  const [first, second] = filing.financial_year.split('-');
  if (
    filing.tax_period === 'January' ||
    filing.tax_period === 'February' ||
    filing.tax_period === 'March'
  ) {
    return Number(second);
  }
  return Number(first);
};

// financial year display format yyyy-yy
// extract yyyy-yyyy data to yyyy-yy

const getFinancialYearDisplay = (filing: GstFilings) => {
  const [first, second] = filing.financial_year.split('-');
  return `${first}-${second.slice(2)}`;
};

const isFiledOnTime = ({ filing }: IsFiledOnTimeParams) => {
  const date = getFilingDate(filing.date_of_filing);
  const dueDate = dayjs(filing.dueDate).toDate();
  return date <= dueDate;
};

const getFilingsData = (filingsData: GstFilings[]) => {
  const returnTypeMap: Record<string, string> = {};

  const formattedGstData: FormattedGstFilings[] = filingsData.map((filing) => {
    const newFiling: FormattedGstFilings = {
      ...filing,
      filedOnTime: false,
      delayedDays: 0,
      taxableYear: getTaxableYear(filing),
      dueDate: filing.due_date ? filing.due_date : addDueDate(filing),
      financialYearDisplay: getFinancialYearDisplay(filing),
      uniqueId: `${filing.financial_year}-${filing.tax_period}-${filing.date_of_filing}`,
    };

    returnTypeMap[filing.return_type] = filing.return_type;
    const [_first, financialYear] = newFiling.financial_year.split('-');
    const formattedFinancialYear = Number(financialYear);
    const filedFinalcialYear = getFilingDate(newFiling.date_of_filing);

    if (
      filing.status === 'Not Filed' ||
      filedFinalcialYear.getFullYear() > formattedFinancialYear
    ) {
      return {
        ...newFiling,
        filedOnTime: false,
        delayedDays: getDelayedDays({
          dateOfFiling: newFiling.date_of_filing,
          dueDate: newFiling.dueDate,
          filedOnTime: newFiling.filedOnTime,
        }),
      };
    }

    if (filing.return_type === FILING_TYPES.GSTR8) {
      newFiling.filedOnTime = isFiledOnTime({ filing: newFiling });
      newFiling.delayedDays = getDelayedDays({
        dateOfFiling: newFiling.date_of_filing,
        dueDate: newFiling.dueDate,
        filedOnTime: newFiling.filedOnTime,
      });
    }

    if (
      filing.return_type === FILING_TYPES.GSTR1 ||
      filing.return_type === FILING_TYPES.GSTR1A
    ) {
      newFiling.filedOnTime = isFiledOnTime({ filing: newFiling });
      newFiling.delayedDays = getDelayedDays({
        dateOfFiling: newFiling.date_of_filing,
        dueDate: newFiling.dueDate,
        filedOnTime: newFiling.filedOnTime,
      });
    }

    if (filing.return_type === FILING_TYPES.GSTR3B) {
      newFiling.filedOnTime = isFiledOnTime({ filing: newFiling });
      newFiling.delayedDays = getDelayedDays({
        dateOfFiling: newFiling.date_of_filing,
        dueDate: newFiling.dueDate,
        filedOnTime: newFiling.filedOnTime,
      });
    }

    if (
      filing.return_type === FILING_TYPES.GSTR9 ||
      filing.return_type === FILING_TYPES.GSTR9C
    ) {
      newFiling.filedOnTime = isFiledOnTime({ filing: newFiling });
      newFiling.delayedDays = getDelayedDays({
        dateOfFiling: newFiling.date_of_filing,
        dueDate: newFiling.dueDate,
        filedOnTime: newFiling.filedOnTime,
      });
    }

    return newFiling;
  });

  return formattedGstData;
};
export const gstFilingsSelector = selector<GstFilingsSelector>({
  key: GET_GST_FILINGS_SELECTOR,
  get: async ({ get }) => {
    const { gst } = get(gstAtom);

    if (!gst) {
      return {
        ...defaultGstSummary,
      };
    }

    const gstFilingResponse: ApiResFormat<GstFilings[]> =
      await getGstFilingsApi();
    const gstFilings = gstFilingResponse.data.data;
    const filteredGstFilings = gstFilings.filter(
      (filing) => filing.gstin === gst,
    );

    if (filteredGstFilings.length === 0) {
      return {
        ...defaultGstSummary,
      };
    }
    const formattedGstData = getFilingsData(filteredGstFilings);
    const gstData = formattedGstData.filter(
      (filing) =>
        filing.financialYearDisplay ===
        formattedGstData[0].financialYearDisplay,
    );

    const onTimeFilings = gstData.filter((filing) => filing.filedOnTime);
    const lateFilings = gstData.filter((filing) => !filing.filedOnTime);
    const ontimeFilingPercentage = Math.round(
      (onTimeFilings.length / gstData.length) * 100,
    );
    // get first year minus 1 year
    const lastFiledDate = dayjs(
      filteredGstFilings[0].date_of_filing,
      'DD/MM/YYYY',
    )
      .subtract(1, 'year')
      .format('DD/MM/YYYY');
    const fileStartDate = filteredGstFilings[0].date_of_filing;

    return {
      gstFilings,
      onTimeCount: onTimeFilings.length,
      lateCount: lateFilings.length,
      ontimeFilingPercentage,
      filteredGstFilings: formattedGstData,
      fileStartDate,
      lastFiledDate,
    };
  },
});

export const getCompanyInfoSelector = selector<CompanyInfo>({
  key: COMPANY_INFO_SELECTOR,
  get: async ({ get }) => {
    const cin = get(cinAtom);
    const companiesResponse: ApiResFormat<CompanyInfo[]> =
      await getCompanyInfoApi();

    const companyFound = companiesResponse.data.data.find(
      (company) => company.company_details.cin === cin,
    );
    if (!cin || !companyFound) {
      return {
        company_details: {
          cin: '',
          gstin: '',
          company_name: '',
          company_status: '',
          roc: '',
          registration_number: '',
          company_category: '',
          company_sub_category: '',
          class_of_company: '',
          date_of_incorporation: '',
          age_of_company: '',
          number_of_members: '',
          activity: '',
        },
        authorized_capital: '',
        paid_up_capital: '',
        listing_status: '',
        last_annual_general_meeting: '',
        latest_balance_sheet: '',
        contact_details: {
          email: '',
          address: '',
        },
      };
    }

    return companyFound;
  },
});
