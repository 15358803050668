import { platfromClient } from './clients';

export interface CompaniesSearch {
  search: string;
  refetch?: boolean;
}

export const companiesSearchApi = async ({
  search,
  refetch,
}: CompaniesSearch) =>
  platfromClient.get('/companies/search', {
    params: {
      search,
      refetch,
    },
  });

interface CompanyProfile {
  cin: string;
}

export const companiesProfileApi = async ({ cin }: CompanyProfile) =>
  platfromClient.get(`/companies/profile`, {
    params: {
      cin,
    },
  });

export const getGstFilingsApi = async () =>
  platfromClient.get('/mock/gst/filings/info');
export const getCompanyInfoApi = async () =>
  platfromClient.get('/mock/companies/info');
export const getTracesInfoApi = async () =>
  platfromClient.get('/mock/traces/info');
export const getRocInfoApi = async () => platfromClient.get('/mock/roc/info');
