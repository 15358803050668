import axios from 'axios';
import { beHost, platformHost } from '@src/configs';

export const platfromClient = axios.create({
  baseURL: `${platformHost}/api/compliance/v1`,
});

export const beClient = axios.create({
  baseURL: `${beHost}/api`,
});

export const postmanClient = axios.create({
  baseURL: 'https://e078fb93-fd3a-415d-9fea-922d5ffd5569.mock.pstmn.io',
});
